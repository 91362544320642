import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import colors from '../utils/color'
import Slider from '../component/slider'
import QuiSommeNous from './quisommenous'
import Prestation from '../component/prestations'
import Creation from '../component/creation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointDown } from '@fortawesome/free-regular-svg-icons'
import Partenaire from '../component/partenaire'
import { useIsVisible } from '../hook/useIsVisible'
import Clients from '../component/clients'
import Footer from '../component/footer'
import BackgroundVideo from '../component/backgroundVideo'
import { useAccessibility } from '../context/AccessibilityContext';


const HomeContainer = styled.div`
    text-shadow: black 0.1em 0.1em 0.2em;
   
`


/* Page Home (page d'accueil) */
function Home() {
    const [data, setData] = useState("")

    useEffect(() => {
        if (data) {
            data?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        setData("")
    }, [data])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const ref1 = useRef();
    const isVisible1 = useIsVisible(ref1);

    const ref2 = useRef();
    const isVisible2 = useIsVisible(ref2);

    const ref3 = useRef();
    const isVisible3 = useIsVisible(ref3);

    const { lineHeight, fontSize, letterSpacing } = useAccessibility();

    /* Return Home container with Banner and map data to card component */
    return (

        <HomeContainer className={`flex justify-around w-full h-full m-auto flex-col pt-80  md:pt-52 lg:pt-36 xl:pt-0 ${fontSize} ${lineHeight} ${letterSpacing} custom-cursor`}>

            <div className="items-center border-b-2 border-[#2a2448] flex flex-col justify-center w-full relative xl:h-screen md:h-96" >
                <BackgroundVideo />
                <div className='min-h-40 flex flex-col justify-center w-full items-center gap-6 md:h-3/4 bg-cover bg-no-repeat h-1/2 relative  z-10 pt-10 pb-10 xl:h-screen xl:m-0 xl:p-0 '   >
                    <h1 className='hidden md:flex text-center text-3xl md:text-3xl xl:text-6xl xl:font-bold tracking-wide md:mt-0 md:mb-0'>Le studio de création multimédia inclusif</h1>
                    <h2 className='hidden text-center text-lg  md:flex xl:font-bold  '>Design et animation 2D/3D - Développement de jeux vidéo – Formation vidéo ludique - Communication FALC & Accessibilité numérique</h2>
                </div>



                <br id='Prestation' className='hidden'></br>
            </div>
            {/*<p className='text presentation md:text-l mt-12 lg:pt-20  md:mt-6 p-5 pt-0 pb-20 text-center text-white tracking-wide '><strong>Place des victoires</strong> est un studio de création multimédia opéré par des personnes en situation de
                handicap ayant développé des "Mad Skills" créatifs : traits de caractères atypiques et décalés. <br></br><br></br> Spécialisés sur l’accessibilité de la création numérique, nous maitrisons les logiciels professionnels tels
                que la <strong>Suite Adobe, Blender, Unity et Unreal</strong> ainsi que les nouvelles technologies de création : <strong>motion

                    capture, animation 3D et intelligence artificielle générative</strong>.
            </p>*/}

            <p className={`text presentation md:text-xl text-l mt-12 lg:pt-20  md:mt-6 p-5 pt-0 pb-20 text-center text-white tracking-wide xl:pt-10 xl:pb-10 xl:m-0 ${lineHeight} ${letterSpacing} xl:ml-36 xl:mr-36`}><strong>Place des victoires</strong> est un studio de création multimédia en Entreprise Adaptée (EA).

                Il est opéré par des personnes en situation de handicap ayant développé des "Mad Skills" créatifs : traits
                de caractères atypiques et décalés. <br></br><br></br> Spécialisés sur l’accessibilité de la création numérique, nous maitrisons les logiciels professionnels tels
                que la <strong>Suite Adobe, Blender, Unity et Unreal</strong> ainsi que les nouvelles technologies de création : <strong>motion

                    capture, animation 3D et intelligence artificielle générative</strong>.
                <br></br><br></br>
                Par ses prestations et son agrément EA, <strong>Place des Victoires permet aux entreprises de +20
                    salariés de respecter leurs obligations en termes d’embauche de travailleurs handicapés</strong>  et de
                réduire une partie de leur taxe AGEFIPH.
            </p>


            <div ref={ref1} >
                <Prestation />
            </div>
            <div id='Partenaire' ></div>
            <div ref={ref3} >
                <Partenaire />
            </div>
            <br id="Creation"></br>
            <div ref={ref2} >
                <Creation />
            </div>
        </HomeContainer >
    )
}

export default Home