import React, { createContext, useState, useContext, useEffect } from 'react';

const AccessibilityContext = createContext();

export const AccessibilityProvider = ({ children }) => {
  const [fontSize, setFontSize] = useState('text-base');
  const [letterSpacing, setLetterSpacing] = useState('letter-spacing-base');
  const [highlightLinks, setHighlightLinks] = useState(false);
  const [lineHeight, setLineHeight] = useState('line-height-base');
  const [cursorStyle, setCursorStyle] = useState('default');


  useEffect(() => {
    document.body.className = cursorStyle + '-cursor';
  }, [cursorStyle]);

  const increaseFontSize = () => {
    setFontSize(prevSize => {
      if (prevSize === 'text-sm') return 'text-base';
      if (prevSize === 'text-base') return 'text-lg';
      if (prevSize === 'text-lg') return 'text-xl';
      return prevSize;
    });
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => {
      if (prevSize === 'text-xl') return 'text-lg';
      if (prevSize === 'text-lg') return 'text-base';
      if (prevSize === 'text-base') return 'text-sm';
      return prevSize;
    });
  };

  const increaseLineHeight = () => {
    setLineHeight(prevHeight => {
      if (prevHeight === 'line-height-base') return 'line-height-md';
      if (prevHeight === 'line-height-md') return 'line-height-lg';
      return prevHeight; // La hauteur de ligne ne dépasse pas 'line-height-lg'
    });
  };

  const decreaseLineHeight = () => {
    setLineHeight(prevHeight => {
      if (prevHeight === 'line-height-lg') return 'line-height-md';
      if (prevHeight === 'line-height-md') return 'line-height-base';
      return prevHeight; // La hauteur de ligne ne descend pas en dessous de 'line-height-base'
    });
  };


  const increaseLetterSpacing = () => {
    setLetterSpacing(prevSpacing => {
      if (prevSpacing === 'letter-spacing-base') return 'letter-spacing-md';
      if (prevSpacing === 'letter-spacing-md') return 'letter-spacing-lg';
      return prevSpacing;
    });
  };

  const decreaseLetterSpacing = () => {
    setLetterSpacing(prevSpacing => {
      if (prevSpacing === 'letter-spacing-lg') return 'letter-spacing-md';
      if (prevSpacing === 'letter-spacing-md') return 'letter-spacing-base';
      return prevSpacing;
    });
  };

  const toggleHighlightLinks = () => {
    setHighlightLinks(prev => !prev); // Bascule la surbrillance des liens
  };

  const setCursorNormal = () => {
    setCursorStyle('default');
  };

  const setCursorBlackLarge = () => {
    setCursorStyle(prev => prev === 'black-large' ? 'default' : 'black-large' );
  };

  const setCursorWhiteLarge = () => {
    setCursorStyle(prev => prev === 'white-large' ? 'default' : 'white-large' );
  };

  return (
    <AccessibilityContext.Provider value={{
      fontSize, increaseFontSize, decreaseFontSize,
      lineHeight, increaseLineHeight, decreaseLineHeight,
      letterSpacing, increaseLetterSpacing, decreaseLetterSpacing,
      highlightLinks, toggleHighlightLinks,
      cursorStyle, setCursorNormal, setCursorBlackLarge, setCursorWhiteLarge
    }}>
      {children}
    </AccessibilityContext.Provider>
  );
};

export const useAccessibility = () => useContext(AccessibilityContext);