import { useEffect, useState } from "react"
import { useAccessibility } from "../context/AccessibilityContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faUniversalAccess, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'


function ModuleAccessibility() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fontSizeName, setFontSizeName] = useState('100%');
    const [lineHeightName, setLineHeightName] = useState('Normal');
    const [letterSpacings, setLetterSpacing] = useState('Normal');
    const {
        fontSize,
        increaseFontSize,
        decreaseFontSize,
        lineHeight,
        increaseLineHeight,
        decreaseLineHeight,
        increaseLetterSpacing, decreaseLetterSpacing, letterSpacing, highlightLinks, toggleHighlightLinks,
        cursorStyle, setCursorNormal, setCursorBlackLarge, setCursorWhiteLarge
    } = useAccessibility();


    const handleClick = () => {
        setIsModalOpen(!isModalOpen)
    }


    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            e.key === 'Escape' && setIsModalOpen(false)
        })
        return () => {
            document.removeEventListener('keydown', (e) => e)
        }
    }, [isModalOpen])

    useEffect(() => {
        if (fontSize === 'text-sm') {
            setFontSizeName('80%')
        }
        if (fontSize === 'text-base') {
            setFontSizeName('100%')
        }
        if (fontSize === 'text-lg') {
            setFontSizeName('120%')
        }
        if (fontSize === 'text-xl') {
            setFontSizeName('140%')
        }

    }, [fontSize])

    useEffect(() => {
        if (lineHeight === 'line-height-base') {
            setLineHeightName('Normal')
        }
        if (lineHeight === 'line-height-md') {
            setLineHeightName('x2')
        }
        if (lineHeight === 'line-height-lg') {
            setLineHeightName('x3')
        }
    }, [lineHeight])

    useEffect(() => {
        if (letterSpacing === 'letter-spacing-base') {
            setLetterSpacing('Normal')
        }
        if (letterSpacing === 'letter-spacing-md') {
            setLetterSpacing('Medium')
        }
        if (letterSpacing === 'letter-spacing-lg') {
            setLetterSpacing('Large')
        }

    }, [letterSpacing])

    console.log(cursorStyle)

    return (
        <div>
            <div className="fixed h-16 w-16 top-[90vh] right-10 bg-transparent group z-50 hidden md:flex">
                <button className="bg-[#13062c] bg-opacity-70 text-white rounded-full h-16 w-16 flex items-center justify-center border border-[#2a2448] group-hover:scale-110 z-50" aria-label="Bouton module accessibilité" onClick={() => handleClick()}>
                    <FontAwesomeIcon icon={faUniversalAccess} className='w-full h-full' />
                </button>
            </div>
            {isModalOpen && (
                <div className="fixed top-[25vh] right-5 w-[400px] h-[600px] z-50">
                    <div className="bg-[#13062c] p-10 rounded-lg border border-[#2a2448] h-full flex flex-col ">
                        <div className="absolute top-20 md:top-0 left-0 p-4">
                            <FontAwesomeIcon icon={faCircleXmark} onClick={() => setIsModalOpen(false)} className='h-6 cursor-pointer' />
                        </div>
                        <h1 className="text-3xl font-bold text-center border-b-2 pb-5">Module d'accessibilité</h1>
                        <p className="text-lg mt-5 text-center border-b-2 pb-5">Ce module est en cours de développement</p>
                        <div className="overflow-hidden overflow-scroll no-scrollbar w-full m-auto">
                            <div className="flex flex-col justify-center m-5">
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p>Taille de la police</p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={decreaseFontSize}
                                        ><FontAwesomeIcon icon={faMinus} /></button>
                                        {fontSizeName}
                                        <button
                                            className="hover:scale-125"
                                            onClick={increaseFontSize}>

                                            <FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                </div>
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p>Hauteur de la ligne</p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={decreaseLineHeight}
                                        ><FontAwesomeIcon icon={faMinus} /></button>
                                        {lineHeightName}
                                        <button
                                            className="hover:scale-125"
                                            onClick={increaseLineHeight}>

                                            <FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                </div>
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p>Espacement des lettres</p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={decreaseLetterSpacing}
                                        ><FontAwesomeIcon icon={faMinus} /></button>
                                        {letterSpacings}
                                        <button
                                            className="hover:scale-125"
                                            onClick={increaseLetterSpacing}>

                                            <FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                </div>
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p>Mettre les liens en surbrillance</p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={toggleHighlightLinks}
                                        >
                                            {highlightLinks ? 'Désactiver' : 'Activer'}
                                        </button>

                                    </div>
                                </div>
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p> Grand Curseur clair </p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={setCursorWhiteLarge}
                                        >
                                            {cursorStyle == 'white-large' ? 'Désactiver' : 'Activer'}
                                        </button>

                                    </div>
                                </div>
                                <div
                                    className={` border-2 border-[#2a2448] hover:bg-[#2a2448] m-2 p-2 w-full text-center`}

                                >
                                    <p> Grand Curseur sombre </p>
                                    <div className="flex justify-around">
                                        <button
                                            className="hover:scale-125"
                                            onClick={setCursorBlackLarge}
                                        >
                                            {cursorStyle == 'black-large' ? 'Désactiver' : 'Activer'}
                                        </button>

                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ModuleAccessibility