import React from 'react';
import { useAccessibility } from "../context/AccessibilityContext";
import { Link } from "react-router-dom";

const Links = ({ to, children, ...props }) => {
    const { highlightLinks } = useAccessibility();
    console.log(highlightLinks);
    return (
        <Link
            to={to}
            style={highlightLinks ? { border: '2px solid yellow' } : {}}
            {...props}
        >
            {children}
        </Link>
    );
};

export default Links;
