import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/color'



const CardContainer = styled(Link)`

    text-shadow: black 0.1em 0.1em 0.2em;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
    p {
        text-shadow: black 0.1em 0.1em 0.2em;
    }
    &:hover {
        svg {
            color: ${colors.background};
        }
    }
    svg {
        font-size: 40px;
        margin: auto;
        margin-top: 30px;
        color: ${colors.text};
    }
`


/* Page Card (page d'accueil) */
function Card({ Icon, title, text, path }) {



    /* Return Card container with Banner and map data to card component */
    return (

        <CardContainer onClick={path} className=' flex flex-col bg-[#2a2448]  overflow-hidden h-80 w-full md:w-1/3 mb-5 mt-5 no-underline text-white rounded-xl border-2 border-[#8a2be2]/[0.2] group hover:rotate-3 m-3  lg:m-0' >
            <Icon />
            <div className='container-text flex flex-col justify-center center h-full'>
                <h2 className='text md:text-lg text-center font-bold tracking-wide'>{title}</h2>
                <p className='p-2.5 text md:text-lg lg:p-0 text-center text-white tracking-wide'>{text}</p>
            </div>
        </CardContainer>
    )
}

export default Card