import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../utils/color'
import Slider from './slider'

/* Page Partenaire (page d'accueil) */
function Partenaire() {

    const icone = [
        '/cours.svg',
        '/logo/agefiph.svg',
        '/logo/CNC.svg',
        '/logo/Nanterre.svg',
        '/logo/ErnstYoung.png',
        "/logo/L'oréal.png",
        '/logo/HOPSCOTCHGROUPE.png',
        '/logo/AXA2.png',
        '/logo/bnp-cardif.svg',
        '/logo/Banque_de_France.png',
        '/logo/vivendi.svg',
        '/logo/credit-agricole-immobilier.png',
    ]


    /* Return Partenaire container */
    return (

        <div className=' flex flex-col justify-center h-full w-full m-auto border-t-2 border-[#2a2448]  pt-10 pb-10'>

            <h1 className='md:flex m-auto my-0 text-3xl text-center md:p-10 pb-5 hidden '>Ils nous ont fait confiance : </h1>
            { /* <div className='flex border-2 border-[#8a2be2]/[0.2] h-96 mb-10 rounded'>
                {array?.map(
                    (item, index) => (
                        <div key={index} className='flex flex-col m-auto '>     
                            <img className='w-full white-icon' src={item} alt='' />
                        </div>
                    )
                )}
            </div> */}
            <Slider array={icone} />

        </div>
    )
}

export default Partenaire