import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import styled from 'styled-components';


const ContactFormArea = styled.textarea`
     &::-webkit-scrollbar {
        width: 0px;
    };
    overflow: hidden;
    overflow-y: auto;
    max-height: 460px;
`



const ContactForm = ({ isModalOpen, setIsModalOpen }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [prenom, setPrenom] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            nom: name,
            prenom: prenom,
            email: email,
            message: message,
        }

        console.log(data);

        const response = await fetch('/api/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        console.log(result);
        setIsModalOpen(false);
    };

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            e.key === 'Escape' && setIsModalOpen(false)
        })
        return () => {
            document.removeEventListener('keydown', (e) => e)
        }
    }, [isModalOpen])

    if (!isModalOpen) return null;

    return (
        <div className='fixed flex w-screen h-screen border-t-2 border-[#2a2448] bg-[#13062c] z-50 pt-80 md:pt-52 lg:pt-36 lg:pb-10'>

            <form onSubmit={handleSubmit} className='relative flex flex-col md:pb-36  md:m-auto  gap-10 bg-[#2a2448]/[0.7] md:p-20 md:pl-10 md:pr-10 lg:mb-20 lg:mt-5 border-2 border-[#2a2448] justify-center lg:w-3/4 xl:w-1/2 md:pt-36 w-full h-full '>
                <div className="absolute top-0 left-0 pt-4 md:pl-2 xl:p-4">
                    <FontAwesomeIcon icon={faCircleXmark} onClick={() => setIsModalOpen(false)} className='h-6 cursor-pointer' />
                </div>
                <h2 className='m-auto text-xl mt-10'>Contactez-nous</h2>
                <div className='flex flex-col md:flex-row h-full w-full p-4'>
                    <div className='md:w-1/2 m-auto flex flex-col gap-5 md:mr-5'>
                        <div className='w-full flex gap-5'>
                            <input
                                className='text-white w-1/2 outline-none rounded p-2 bg-[#2a2448] '
                                type="text"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                placeholder='Prénom'
                            />
                            <input
                                className='text-white w-1/2 outline-none rounded p-2 bg-[#2a2448] '
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Nom'
                            />


                        </div>
                        <input
                            className='text-white outline-none rounded p-2 bg-[#2a2448] mb-10'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email'
                        />
                    </div>

                    <ContactFormArea
                        className='text-white md:h-96 p-2 outline-none md:w-1/2 bg-[#2a2448] w-full h-32'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder='Message'
                    />
                </div>
                <button className='border-2 border-[#2a2448] hover:bg-[#2a2448] m-auto p-2 mb-5 md:mb-0 md:mt-4' type="submit">Envoyer</button>
                <p className='text-center mb-10'>Ou directement à : <a href='mailto:contact@placedesvictoires.tech'>contact@placedesvictoires.tech</a></p>
            </form>
        </div>
    );
};

export default ContactForm;
