// VideoFlexGrid.js
import React from 'react';
import Links from './Link';
import YouTubeVideoWithThumbnail from './FacadeVideo';

const VideoFlexGrid = ({ mediaItems }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
      {mediaItems.map((item, index) => {
        const isVideo = item.type === 'video';

        return (
          <div
            className="flex flex-col  rounded-lg  shadow-lg"
            key={index}
          >
            <div className="w-full aspect-w-16 aspect-h-9 relative">
              {isVideo ? (
                <YouTubeVideoWithThumbnail videoId={item.url} title={item.title} />
              ) : item.type === 'link' ? (
                <Links href='https://souslesdraps.org/' target='_blank' className='w-full h-full'>
                  <img
                    className="object-cover w-full h-full rounded-t-lg"
                    src={item.url}
                    alt={item.title}
                  />
                </Links>
              ) : (
                <img
                  className="object-cover w-full h-full rounded-t-lg"
                  src={item.url}
                  alt={item.title}
                />
              )}
            </div>
            <p className="mt-2 px-4 pb-4 text-center text-sm xl:text-base text-white line-clamp-2">
              {item.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default VideoFlexGrid;
