import React from 'react';

function Gallery({ images, version }) {


    return (
        <>
            {version === 'prestation' ?

                <div className=" container flex flex-col justify-between gap-5 xl:gap-3 mb-0 w-full md:flex-row md:flex-wrap xl:flex-nowrap md:m-3 xl:m-0">
                    {images?.map((image, index) => (
                        <div key={index} className="relative group overflow-hidden rounded md:rounded-lg w-full h-full lg:w-2/6 m-auto xl:m-0 max-h-[380px] max-w-[320px]" >
                            <img
                                src={image.image}
                                alt={`Image ${index} - ${image.titre}`}
                                className="w-full h-full  object-cover  lg:group-hover:scale-125 duration-700"
                            />
                            <div className="absolute inset-0 flex flex-col items-center justify-center lg:opacity-0 lg:hover:opacity-100 lg:transition-opacity duration-300">
                                <h1 className="text-2xl text-white tracking-wide text-center">{image.titre}</h1>
                                {image.text && <p className="text-white text-center">{image.text}</p>}
                            </div>
                        </div>
                    ))}
                </div>
                :
                <div className="container flex flex-col md:p-10 lg:p-0 lg:gap-5 lg:min-h-[278px]  lg:flex-row justify-between gap-10 xl:gap-3 mb-0 w-full md:m-3 lg:m-0">
                    {images?.map((image, index) => (
                        <div key={index} className="relative group overflow-hidden rounded md:rounded-lg w-full h-full lg:w-2/6 xl:m-0  " >
                            <img
                                src={image.image}
                                alt={`Image ${index} - ${image.titre}`}
                                className="w-full h-full  object-cover  lg:group-hover:scale-125 duration-700"
                            />
                            <div className="absolute inset-0 flex flex-col items-center justify-center lg:opacity-0 lg:hover:opacity-100 lg:transition-opacity duration-300">
                                <h1 className="text-2xl text-white tracking-wide text-center">{image.titre}</h1>
                                {image.text && <p className="text-white text-center">{image.text}</p>}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    )
};

export default Gallery;
