import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../utils/color'
import Card from './card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversalAccess, faPaintBrush, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'


const ClientsContainer = styled.div`

    h1 {
        text-shadow: black 0.1em 0.1em 0.2em;
     }

`


/* Page Clients (page d'accueil) */
function Clients() {

    let text = [{ text: '• Outils publicitaires en 2D/3D\n• Jeux vidéo\n• Formations vidéoludiques • Outils de communication accessibles', image: faPaintBrush, title: 'Création multimédia', path: '/creationnumerique' },
    { text: '• Audit d’accessibilité numérique • Mise en accessibilité universelle avec traduction en Facile à Lire et à Comprendre (FALC)', image: faUniversalAccess, title: 'Accessibilité et FALC', path: '' },
    { text: '• Ateliers artistiques •Ateliers numériques', image: faLightbulb, title: 'Sensibilisation Handicap', path: '/sensibilisation' },
    { text: '• Accueil des publics en situation de handicap • Mise en accessibilité des supports de communication', image: faGraduationCap, title: 'Formations', path: '/formation' }]


    /* Return Clients container with Banner and map data to card component */
    return (

        <ClientsContainer className='border-b-2 border-[#2a2448] flex flex-col justify-center w-full h-screen pt-24 '>
            <h1 id='Client' className='text-center text-white text-3xl mb-12 tracking-wide'>Nos clients</h1>
            <div className='flex justify-around text-center gap-6 w-full'>
                <div className="container flex wrap justify-around w-full h-full">
                    {text.map((el, index) => {
                        // Séparer le texte sur '•', filtrer les segments vides, et les mapper dans des éléments JSX
                        const segments = el.text.split('•').filter(segment => segment.trim().length);
                        const formattedText = segments.map((segment, idx) => (
                            <div key={idx}>{`• ${segment.trim()}`}</div>
                        ));
                        return <Card key={index} Icon={() => <FontAwesomeIcon icon={el.image} />} title={el.title} text={formattedText} path={el.path} />;
                    })}
                </div>

            </div>
            <div className='container-image flex flex-col justify-center m-auto mt-24 rounded-xl w-9/12 p-12 border-2 border-[#8a2be2]/[0.2]'>

            </div>
            <br id="Creation"></br>
        </ClientsContainer>
    )
}

export default Clients