import React from 'react'
import Route from './routes/routes'
import { createRoot } from 'react-dom/client'
import './index.css'
import { AccessibilityProvider } from './context/AccessibilityContext';



const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AccessibilityProvider>
      <Route />
    </AccessibilityProvider>
  </React.StrictMode>
)
