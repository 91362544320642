// YouTubeVideoWithThumbnail.js
import React, { useState } from 'react';

/* Composant de façade pour les vidéos YouTube */
const YouTubeVideoWithThumbnail = ({ videoId, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  // URL de la miniature de la vidéo YouTube
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  const handleClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="w-full h-full">
      {!isPlaying ? (
        // Afficher la miniature si la vidéo n'est pas en lecture
        <div className="relative w-full h-full cursor-pointer" onClick={handleClick}>
          <img
            src={thumbnailUrl}
            alt={`Thumbnail for ${title}`}
            className="object-cover w-full h-full rounded-t-lg"
          />
          {/* Bouton de lecture stylisé par-dessus la miniature */}
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-4xl text-white">▶</span>
          </div>
        </div>
      ) : (
        // Intégrer la vidéo YouTube lorsque l'utilisateur clique
        <iframe
          className="w-full h-full "
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
        />
      )}
    </div>
  );
};

export default YouTubeVideoWithThumbnail;
