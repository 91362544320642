import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ContactForm from './contactform'
import colors from '../utils/color'
import { useAccessibility } from '../context/AccessibilityContext'
import Links from './Link'

const HeaderContainer = styled.div`
backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
transition: backdrop-filter 0.3s ease-in-out;

`

/* Composant Header */
function Header({ setIsModalOpen, isModalOpen }) {
    const [data, setData] = useState("")
    const creation = document.getElementById('Creation')
    const partenaire = document.getElementById('Partenaire')


    let navigate = useNavigate()

    let location = useLocation();

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        if (data) {
            data?.scrollIntoView({ behavior: 'smooth' })
        }
        setData("")
    }, [data])


    const Contact = (e) => {
        e.preventDefault()
        if (isModalOpen) setIsModalOpen(false)
        else setIsModalOpen(true)
    };

    const navigateAndScroll = (to) => {
        if (!to) return
        navigate('/'); // Navigue vers la page d'accueil
        if (to == 'creation') {
            setTimeout(() => {
                const creations = document.getElementById('Creation');
                if (creations) {
                    creations.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500); // Vous pouvez ajuster ce délai si nécessaire
        }

        if (to == 'partenaire') {
            setTimeout(() => {
                const partenaires = document.getElementById('Partenaire');
                if (partenaires) {
                    partenaires.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500); // Vous pouvez ajuster ce délai si nécessaire
        }
    };

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.play();
        }
    }, [location.pathname]);


    const { fontSize, lineHeight } = useAccessibility();

    /* Return Header container  */
    return (

        <HeaderContainer className={`xl:container flex flex-wrap flex-col md:flex-row md:justify-around md:items-center fixed w-full xl:max-w-none z-50 bg-[#13062c] bg-opacity-70 border-b-2 border-[#2a2448] ${fontSize}`} >

            {
                location.pathname != '/' ?
                    <Links to={'/'} className='flex w-full justify-center lg:w-fit' aria-label='Icone de Place des victoires' ><video ref={videoRef} width="250" loop autoplay muted >
                        <source src="/logo6.webm" type="video/webm" />
                    </video></Links>
                    :
                    <Links onClick={(e) => scrollToTop(e)} className='flex w-full justify-center lg:w-fit' aria-label='Icone de Place des victoires'>{ /* <img src="/logo-test.png" alt="logo" className='animate-slideDown w-24 h-24 m-4 2xl:ml-28 cursor-pointer' />*/}
                        <video ref={videoRef} width="250" loop autoplay muted>
                            <source src="/logo6.webm" type="video/webm" />
                        </video>
                    </Links>
            }

            <div className='flex flex-col md:flex-row xl:m-auto md:items-baseline  gap-2 md:gap-4  justify-around items-center  '>
                {location.pathname === '/' ?
                    <Links className='border-b-[2px] text-lg  ' onClick={(e) => scrollToTop(e)}>Accueil</Links>
                    :
                    <Links className=' text-lg underline-animation' to={'/'}>Accueil</Links>

                }
                <div className='flex justify-around gap-2 lg:gap-4 w-full flex-wrap'>
                    <Links className={location.pathname === '/quisommenous' ? 'border-b-[2px] text-lg' : 'text-lg underline-animation w-fit'} to={'/quisommenous'}>Qui sommes nous?</Links>
                    {
                        location.pathname === '/prestations' ?
                            <Links className='border-b-[2px] text-lg' onClick={(e) => scrollToTop(e)}>Nos prestations</Links>
                            :
                            <Links className='text-lg underline-animation' to={'/prestations'}>Nos prestations</Links>
                    }
                </div>
                {location.pathname === '/' ?
                    <div className='flex justify-around mb-5 lg:mb-0 gap-4 w-full md:w-fit'>
                        <Links className='md:w-max w-full xl:flex xl:items-center xl:justify-center text-center text-lg w-1/3 ' onClick={() => setData(creation)}><p className=' underline-animation'>Nos créations</p></Links>
                        {/*<Link className=' xl:flex xl:items-center xl:justify-center text-center md:text-lg w-1/3 xl:w-full' onClick={() => setData(partenaire)} ><p className='hover:border-b-[2px]'>Nos partenaires</p></Link>*/}
                    </div>
                    :
                    <div className='w-full  flex justify-around mb-5 lg:mb-0 xlg:mb-0 gap-4 w-full md:w-fit' >
                        <Links className='md:w-max w-full xl:flex xl:items-center xl:justify-center text-center text-lg w-1/3' onClick={() => navigateAndScroll('creation')}><p className=' underline-animation'>Nos créations</p></Links>
                        {/*<Link className='xl:flex xl:items-center xl:justify-center text-center xl:text-lg w-1/3 xl:w-1/2' onClick={() => navigateAndScroll('partenaire')} ><p className='hover:border-b-[2px]'>Nos partenaires</p></Link>*/}
                    </div>

                }

            </div>

            <Links className=' m-auto mb-5 lg:mb-auto md:ml-0  xl:flex xl:items-center xl:justify-center text-center xl:text-lg md:mr-0 lg:mr-8 border border-[#2a2448] p-2 md:p-3 lg:p-4 rounded  bg-gradient-animation' onClick={(e) => Contact(e)} >Nous contacter</Links>
        </HeaderContainer>
    )
}

export default Header