import React, { useEffect, useState, useRef } from 'react';

const BackgroundVideo = () => {
    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const onLoadedMetadata = () => {
      const randomTime = Math.random() * video.duration;
      video.currentTime = randomTime;
      video.play();
    };

    video.addEventListener('loadedmetadata', onLoadedMetadata);

    return () => {
      video.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-full overflow-hidden -z-10 xl:pt-[150px] ">
      <video ref={videoRef} autoPlay muted loop className="fillWidth">
        <source src="https://storage.googleapis.com/base_de_donnee_video_image_creativehandicap/TOUT%20(1).mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;
