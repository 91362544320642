import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversalAccess, faPaintBrush, faGraduationCap, faCube } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import Gallery from './gallery';
import { useNavigate } from 'react-router-dom';
import Links from './Link'

const Prestation = () => {
    const navigate = useNavigate();

    const navigateAndScroll = (to) => {
        if (!to) return;
        navigate('/prestations');
        setTimeout(() => {
            const element = document.getElementById(to);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    };

    const services = [
        {
            text: 'Outils publicitaires en 2D/3D, Jeux vidéo, Formations vidéoludiques, Outils de communication accessibles',
            icon: faPaintBrush,
            title: 'Création multimédia',
            path: 'multimedia',
        },
        {
            text: "Images de synthèse, Films d'animation, Environnements augmentés, Fichiers d’usinage, pour machines CNC",
            icon: faCube,
            title: 'Modélisation 3D',
            path: 'modelisation',
        },
        {
            text: 'Audit d’accessibilité numérique, Mise en accessibilité universelle avec traduction en Facile à Lire et à Comprendre (FALC)',
            icon: faUniversalAccess,
            title: 'Accessibilité et FALC',
            path: 'accessibilité',
        },
        {
            text: 'Ateliers artistiques, Ateliers numériques',
            icon: faLightbulb,
            title: 'Sensibilisation Handicap',
            path: 'sensibilisation',
        },
        {
            text: 'Accueil des publics en situation de handicap, Mise en accessibilité des supports de communication',
            icon: faGraduationCap,
            title: 'Formations',
            path: 'formation',
        },
    ];

    const images = [
        { image: '/entreprises.png', titre: 'Pour les entreprises et collectivités' },
        { image: '/studiodejeux.png', titre: 'Pour les studios de jeux vidéo' },
        { image: '/formation.png', titre: 'Pour les organismes de formation' },
    ];

    return (
        <div className="bg-[#13062c] text-white py-12 h-full border-t-2 border-[#2a2448]">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-bold text-center mb-12">Nos prestations</h1>
                <div className="flex flex-wrap gap-8 justify-center ">
                    {services.map((service, index) => (
                        <Links
                            key={index}
                            className={`bg-[#2a2448] rounded-lg p-6 text-center shadow-lg cursor-pointer transform hover:rotate-3 transition-transform group lg:min-h-[300px] flex flex-col ${index >= 3 ? 'w-full md:w-1/2 lg:w-[30%]' : 'w-full md:w-1/2 lg:w-[30%]'
                                }`}
                            onClick={() => navigateAndScroll(service.path)}
                        >
                            <div className="text-3xl md:text-5xl mb-4">
                                <FontAwesomeIcon icon={service.icon} />
                            </div>
                            <h2 className="text-lg md:text-xl font-semibold mb-4">{service.title}</h2>
                            <p className="text-gray-400">
                                {service.text.split(',').map((item, idx) => (
                                    <div key={idx}>{item.trim()}</div>
                                ))}
                            </p>
                        </Links>
                    ))}
                </div>

                <h2 className="text-3xl font-bold text-center mt-16 mb-8">Nos clients</h2>
                <div className="flex justify-center lg:m-0 md:m-20 md:mt-0 md:mb-0">
                    <Gallery images={images} version={'home'} />
                </div>
                <div className="relative group w-full overflow-hidden mx-auto rounded-lg mt-10">
                    <div className="flex flex-wrap lg:flex-nowrap lg:h-[278px]">
                        <img
                            src="/decors1.png"
                            alt="Image 1"
                            className="w-full md:w-1/2 lg:w-1/4 hidden md:flex h-[278px] object-cover duration-300 lg:group-hover:scale-110"
                        />
                        <img
                            src="/decors2.png"
                            alt="Image 2"
                            className="w-full md:w-1/2 lg:w-1/4 hidden md:flex h-[278px] object-cover duration-300 lg:group-hover:scale-110"
                        />
                        <img
                            src="/decors3.png"
                            alt="Image 3"
                            className="w-full md:w-1/2 lg:w-1/4  md:flex md:h-[278px] object-cover duration-300 lg:group-hover:scale-110"
                        />
                        <img
                            src="/decors4.png"
                            alt="Image 4"
                            className="w-full md:w-1/2 lg:w-1/4 hidden md:flex h-[278px] object-cover duration-300 lg:group-hover:scale-110"
                        />
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-700 rounded-lg">
                        <p className="text-white text-2xl text-center px-4 tracking-wide">
                            Pour les industries créatives et techniques
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Prestation;
