import React from 'react';
import styled, { keyframes } from 'styled-components';

const cardWidth = 160; // Largeur de chaque carte
const cardMargin = 10; // Marge droite de chaque carte

// Animation pour le défilement infini
const scrollAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const SliderContainer = styled.div`
  width: 70%; /* Largeur du slider */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
   mask-image: linear-gradient(to right, transparent, black 128px, black calc(100% - 128px), transparent);
  -webkit-mask-image: linear-gradient(to right, transparent, black 128px, black calc(100% - 128px), transparent);
`;

const InfiniteSlider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  animation: ${scrollAnimation} ${props => props.scrollDuration}s linear infinite;
  white-space: nowrap; /* Assure que les éléments ne s'enroulent pas */
`;

const SlideItem = styled.div`
  flex-shrink: 0;
  width: ${cardWidth}px;
  height: 80px; /* Ajustez selon votre design */
  margin-right: ${cardMargin}px;
  display: inline-block;
`;

const ShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  }

  &:last-child {
    left: auto;
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  }
`;

const Slider = ({ array }) => {
  // Dupliquer le tableau pour l'effet infini
  const items = [...array, ...array, ...array]; // Dupliquer le tableau

  // Calculer la largeur totale du slider
  const totalItemsWidth = (cardWidth + cardMargin) * array.length; // Largeur totale des éléments
  const scrollDuration = totalItemsWidth / 40; // Ajuster la durée du défilement

  return (
    <SliderContainer>
      <ShadowOverlay />
      <ShadowOverlay style={{ right: 0 }} />
      <InfiniteSlider scrollDuration={scrollDuration}>
        {items.map((item, index) => (
          <SlideItem key={index}>
            <img src={item} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} className='white-icon' />
          </SlideItem>
        ))}
      </InfiniteSlider>
    </SliderContainer>
  );
};

export default Slider;
