import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AnimatedText from '../component/prestationText';
import BackgroundVideo from '../component/backgroundVideo';
import { useAccessibility } from '../context/AccessibilityContext';
import Links from '../component/Link';


const PrestationContainer = styled.div`
    text-shadow: black 0.1em 0.1em 0.2em;
   
`


const PagePrestations = () => {

  const [data, setData] = useState("")

  const multimedia = document.getElementById('multimedia')
  const accessibilité = document.getElementById('accessibilité')
  const sensibilisation = document.getElementById('sensibilisation')
  const formation = document.getElementById('formation')
  const modelisation = document.getElementById('modelisation')

  useEffect(() => {
    if (data) {
      if (data === "multimedia") multimedia.scrollIntoView({ behavior: 'smooth' });
      if (data === "modelisation") modelisation.scrollIntoView({ behavior: 'smooth' });
      if (data === "accessibilité") accessibilité.scrollIntoView({ behavior: 'smooth' });
      if (data === "sensibilisation") sensibilisation.scrollIntoView({ behavior: 'smooth' });
      if (data === "formation") formation.scrollIntoView({ behavior: 'smooth' });
    }
    setData("")
  }, [data])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const { fontSize, lineHeight } = useAccessibility();

  return (
    <PrestationContainer className='flex flex-col items-center w-full h-full pt-80 md:pt-52 lg:pt-36 xl:pt-0'>
      <div className='hidden md:flex h-full w-full relative md:h-96 xl:h-screen'>
        <BackgroundVideo />
      </div>
      <div className=' flex-wrap  h-full pb-36 w-full'>

        <AnimatedText></AnimatedText>

        <p className={` text-center pt-24 lg:pl-6 lg:pr-6 text-lg ${lineHeight}`}>Par ses prestations et son agrément « Entreprise Adaptée », Place des Victoires permet aux entreprises
          de +20 salariés de respecter leurs obligations en termes d’embauche de travailleurs handicapés et de réduire une
          partie de leur taxe AGEFIPH (Contactez-nous !)</p>
      </div>
      <div className='w-full xl:w-fit xl:p-10' id='multimedia'>
        <div className='bg-[#2a2448] shadow-lg rounded-lg p-4 lg:p-6 xl:p-8 mb-24 h-full w-full  xl:m-auto xl:mb-24 lg:pt-0'>
          <div className='flex flex-col m-auto pb-24 pt-24 '>
            <h1 className='text-center text-white text-xl mb-12 tracking-wide  '>Création Multimédia</h1>
            <div className='flex-col lg:flex-row flex justify-around items-center'>
              <ul className='flex flex-wrap flex-col md:flex justify-center gap-10 lg:w-1/2 text-center lg:text-left'>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Vidéo publicitaires en motion design ou 3D</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Learning Management System (LMS) accessible</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Trophées digitaux en 2D/3D</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Design graphique des supports de communication en Facile A Lire et à Comprendre (FALC)</li>
              </ul>
              <div className='flex flex-col justify-center gap-10 mt-10 lg:w-1/2 max-w-[500px] h-full w-full items-center' >
                <Links className="w-full md:h-[280px]"><iframe title='Vidéo publicitaire AXA' className=" hidden md:flex rounded-lg shadow-md hover:shadow-xl transition duration-300 w-full md:h-[280px]" src="https://www.youtube.com/embed/h7-niOvadgg?si=tkdY3t6VhiPHJlfe" allow="autoplay; encrypted-media" allowFullScreen></iframe></Links>
                <Links><a href='https://souslesdraps.org' target="_blank" className='rounded-lg shadow-md hover:shadow-xl transition duration-300 w-full md:h-[280px]'>
                  <img className='h-full w-full rounded object-cover' src='/souslesdraps.png' alt='creation' />
                </a>
                </Links>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-[#2a2448] shadow-lg xl:rounded-lg p-4 lg:p-6 xl:p-8 mb-24 h-full w-full' id='modelisation'>
          <div className=' flex flex-col m-auto  pb-24 pt-24'>
            <h1 className='text-center text-white text-xl mb-12 tracking-wide '>Modélisation 3D</h1>
            <div className='flex flex-col lg:flex-row  md:flex-col-reverse  justify-around items-center '>
              <div className='flex flex-col  md:w-full justify-center gap-10 mt-10 lg:w-1/2 max-w-[500px] h-full items-center '>
                <img className='hidden md:flex  h-full w-full rounded object-cover md:h-[280px]' src='/decors3.png' alt='creation' />
                <img className='h-full w-full  rounded object-cover md:h-[340px]' src='/decors2-1.png ' alt='creation' />
              </div>

              <ul className='flex flex-wrap flex-col justify-center gap-10 lg:w-1/2  text-center pt-10 lg:text-right'>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Images de synthèse</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Films d'animation</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Environnements augmentés</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Réalité virtuelle</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Simulation immersive</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Fichiers d’usinage pour machines à commandes numériques (CNC)</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Impression 3D</li>
              </ul>

            </div>
          </div>
        </div>
        <div className='bg-[#2a2448] shadow-lg xl:rounded-lg p-4 lg:p-6 xl:p-8 mb-24 h-full w-full' id='accessibilité'>
          <div className=' flex flex-col m-auto  pb-24 pt-24'>
            <h1 className='text-center text-white text-xl mb-12 tracking-wide '>Accessibilité et FALC</h1>
            <div className='flex flex-col lg:flex-row  md:flex-col-reverse lg:flex-row-reverse justify-around items-center '>
              <div className='flex flex-col  md:w-full justify-center gap-10 mt-10 lg:w-1/2 max-w-[500px] h-full items-center '>
                <img className='hidden md:flex  h-full w-full rounded object-cover md:h-[280px]' src='/pdf accessible.JPG' alt='creation' />
                <img className='h-full w-full  rounded object-cover md:h-[280px]' src='/livret.png ' alt='creation' />
              </div>

              <ul className='flex flex-wrap flex-col justify-center gap-10 lg:w-1/2  text-center pt-10 lg:text-left'>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Mise en accessibilité de site internet</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Traduction en Facile A Lire et à Comprendre (FALC)</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• PDF accessible</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Enregistrement audio</li>
              </ul>

            </div>
          </div>
        </div>
        <div id='sensibilisation' className='bg-[#2a2448] shadow-lg xl:rounded-lg p-4 lg:p-6 xl:p-8 mb-24 h-full w-full' >
          <div className='border-b-2 border-[#2a2448] pb-12 flex flex-col pt-12'>
            <h1 className='text-center text-white text-xl mb-12 tracking-wide '>Sensibilisation Handicap</h1>
            <div className='flex flex-col lg:flex-row lg:ml-auto lg:mr-auto justify-around m-auto lg:flex-row-reverse'>
              <ul className='flex flex-wrap flex-col justify-center gap-10 md:w-[700px] text-center lg:text-right md:mb-10'>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300 '>• Fresque collective</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Fresque collective numérique</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Sculpture collective</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Mise en situation</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300 '>• Prestations sur-mesure</li>
              </ul>
              <div className='flex flex-col justify-center gap-10 mt-10 lg:w-1/2 max-w-[500px] h-full items-center md:m-auto '>
                <img className='h-[280px] w-full rounded object-cover' src='/photo sensibilisation sculpture.jpg' alt='creation' />
                <img className='h-[280px] w-full rounded object-cover ' src='/sensibilisation au handicap photo.jpg ' alt='creation' />
              </div>
            </div>
          </div>
        </div>
        <div id='formation' className='bg-[#2a2448] shadow-lg xl:rounded-lg p-4 lg:p-6 xl:p-8 mb-24 lg:mb-0 xl:mb-24  h-full w-full' >
          <div className=' pb-24 flex flex-col m-auto  pt-24'>
            <h1 className='text-center text-white text-xl mb-12 tracking-wide '>Formations</h1>
            <div className='flex flex-col lg:flex-row  md:flex-col-reverse justify-around lg:flex-row-reverse'>
              <div className='flex flex-col justify-center gap-10 mt-10 md:w-full lg:w-1/2 max-w-[500px] h-full items-center m-auto '>
                <img className='h-[280px] w-full rounded object-cover' src='/foch.jpg' alt='creation' />
                <img className='h-[280px] w-full rounded object-cover' src='/sensi EY.jpg ' alt='creation' />
              </div>

              <ul className='flex flex-wrap flex-col justify-center gap-10 md:w-1/2  mt-10 text-center lg:text-left md:m-auto'>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Formation à l’accueil de personnes en situation de handicap</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Formation au FALC</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• PDF accessible</li>
                <li className='text-white text-lg hover:text-gray-300 transition duration-300'>• Formation à la communication digitale universelle</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </PrestationContainer>
  );
};


export default PagePrestations;
