import Layout from '../component/layout'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from '../pages/home'
import QuiSommeNous from '../pages/quisommenous'
import PagePrestations from '../pages/pageprestations'


//All routes of the application are defined here

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,

            },
            {
                path: '/quisommenous',
                element: <QuiSommeNous />,
            },
            {
                path: '/prestations',
                element: <PagePrestations />,
            }

        ],
    },
])



function Route() {
    return <RouterProvider router={router} />
}

export default Route