// Creation.js
import React from 'react'
import styled from 'styled-components'
import VideoFlexGrid from './Videogrid'

const CreationContainer = styled.div`
    h1 {
        text-shadow: black 0.1em 0.1em 0.2em;
    }
`

/* Page Creation (page d'accueil) */
function Creation() {

    const mediaItems = [
        { url: '/souslesdraps.png', type: 'link', title: 'LMS accessible et contenus multimédia 2D/3D pour Sous Les DRAPS' },
        { url: 'h7-niOvadgg', type: 'video', title: 'Vidéo publicitaire en Motion Design pour AXA' },
        { url: 'AJuYVlcHjs4', type: 'video', title: "L'accessibilité des meetings politiques pour le CNCPH" },
        { url: '/livret.png', type: 'image', title: "Livret d’accueil ACCESSIBLE pour les patients de l’hôpital FOCH" },
        { url: 'dBiCQjq1ax0', type: 'video', title: 'Carte de voeux animées' },
        { url: 'sgU8XHr5u58', type: 'video', title: 'Animation pour les jeux paralympiques 2024' },
        { url: 'oQSq8_KYwRU', type: 'video', title: 'Carte de visite publicitaire en réalité augmentée pour le Crédit Agricole Immobilier' },
        { url: '3MRoNeqFCC0', type: 'video', title: 'Création 2D et 3D des apprenants de la formation C.I.A.R.A' },
        { url: 's6OdIzlvZBI', type: 'video', title: 'Motion design des apprenants de la formation C.I.A.R.A' },
        { url: 'pEU2_cJfZgk', type: 'video', title: 'Modélisation 3D pour jeux des apprenants formation CIARA' },
    ]

    /* Return Creation container with Banner and map data to card component */
    return (
        <CreationContainer className='border-t-2 border-[#2a2448] bg-[#13062c] flex flex-col w-full m-auto pb-6 md:p-12 xl:p-40 xl:pt-10 xl:pb-0'>
            <h1 className='text-center text-3xl  xl:mb-12 xl:mt-12 text-white m-5'>
                Nos Créations
            </h1>
            <VideoFlexGrid mediaItems={mediaItems} />
        </CreationContainer>
    )
}

export default Creation
