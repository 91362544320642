import React from 'react';

const AnimatedText = () => {
    return (
        <div className="flex items-center justify-center xl:mr-96 h-screen w-full hidden lg:mt-[120px]  lg:flex lg:h-96 lg:absolute lg:top-0 lg:left-0 xl:top-[18%]">
            <h1 className="text-3xl font-bold flex w-full ">
                <div className="w-full">
                    <div className="content__container lg:max-w-[1000px] xl:max-w-[1300px] xl:m-auto">
                        <p className="content__container__text lg:ml-[14%] xl:ml-[22%] "> Avec vous pour</p>
                        <ul className="content__container__list lg:ml-[37%] xl:ml-[40%]">
                            <li className="content__container__list__item">de la création multimédia accessible.</li>
                            <li className="content__container__list__item">de la mise en accessibilité de vos contenu.</li>
                            <li className="content__container__list__item">de la sensibilisation sur le handicap.</li>
                            <li className="content__container__list__item">de la formation sur l'accessibilité.</li>
                        </ul>
                    </div>
                </div>
            </h1>
        </div>
    );
};

export default AnimatedText;
