import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Gallery from '../component/gallery'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLayoutEffect } from "react"
import { useAccessibility } from '../context/AccessibilityContext';
import Links from '../component/Link'

// Enregistrez le plugin ScrollTrigger
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


const QuiSommeNousContainer = styled.div`
text-shadow: black 0.1em 0.1em 0.2em;
 
`


/* Page QuiSommeNous (page d'accueil) */
function QuiSommeNous() {


    const { fontSize, lineHeight, letterSpacing } = useAccessibility();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const images = [{ image: 'Nicolas.svg', titre: 'Nicolas Pierret', text: 'Directeur du développement' },
    { image: 'Solene.jpeg', titre: 'Solène Lebret', text: 'Cheffe de projet 2D/3D et cinématique' },
    { image: 'Alice.jpeg', titre: 'Alice Rosier', text: 'Professeure en Design Graphique' },
    { image: 'Alexandre.jpeg', titre: 'Alexandre Borikic', text: 'Professeur de modélisation 3D' },
    { image: 'nora.jpg', titre: 'Nora Elkebir', text: 'Directrice de la communication' },
    { image: 'Jean-Baptiste-Guerin.jpg', titre: 'Jean-Baptiste Guerin', text: 'Développeur web' },
    ]

    /* Return QuiSommeNous container with Banner and map data to card component */
    return (

        <QuiSommeNousContainer className={`w-full flex flex-wrap  pt-80 md:pt-52 lg:pt-36  `} >
            <div id='bloc1' className={` panel  m-auto  flex flex-col xl:flex-row w-full  border-b-2 border-[#2a2448] xl:h-screen `} >
                <div className={`flex flex-col lg:flex-row md:p-12 rounded pt-12 lg:p-6 lg:m-5 xl:mt-16 xl:w-1/2 overflow-hidden w-fit  h-fit lg:h-full xl:h-fit bg-[#13062c] p-6 rounded shadow-xl border-b-2 border-[#2a2448] xl:border-b-0`}>

                    <p className={`text-lg  ${lineHeight} ${letterSpacing} ${fontSize}`}><strong>PLACE DES VICTOIRES</strong> est le Studio de Création multimédia en entreprise adaptée de <strong>CREATIVE HANDICAP</strong>. <br></br> <br></br><strong>CREATIVE HANDICAP</strong> est une association loi 1901 a but non
                        lucrative, fondée en 2004 et reconnue d'intérêt général ayant
                        pour objet <strong>l'inclusion socio-professionnelle des personnes en
                            situation de handicap</strong> ou éloignées de l'emploi par <strong>la création
                                artistique et numérique</strong>.
                        <br></br><br></br>
                        Creative Handicap a créé la <strong>première formation professionnelle à destination des personnes
                            en situation de handicap ou décrocheurs scolaires sur les métiers de la création numérique</strong>:
                        Design graphique, animation 2D/3D, conception de jeux vidéo.
                        <br></br><br></br>
                        <strong>La mission du studio</strong> est de créer un cercle vertueux :
                        <br></br><br></br>
                        <strong>Formation <FontAwesomeIcon icon={faArrowRight} className='text-white'></FontAwesomeIcon> Autonomie <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> Emploi </strong>
                    </p>
                </div>
                <div className='flex flex-col md:flex-wrap md:flex-row w-full xl:w-1/2 xl:mt-16  md:mb-12 md:mt-10 gap-5 lg:m-auto lg:justify-center lg:pb-5'>
                    <div className='flex flex-col rounded-tl-[50%] rounded-tr-[50%] group m-auto lg:m-0 mt-0 mb-0 h-72 md:w-2/5 hidden md:flex'>
                        <img src="/presentation1-4.png" alt="logo" className='object-cover lg:blur-[2px] group-hover:blur-none duration-300 h-full' />
                    </div>
                    <div className='flex flex-col rounded-tr-[50%] rounded-br-[50%] group mt-0 m-auto lg:m-0 mb-0 h-72 md:w-2/5'>
                        <img src="/presentation2-4.png" alt="logo" className='object-cover md:group-hover:scale-110 duration-700 h-full' />
                    </div>
                    <div className='flex flex-col rounded-br-[50%] rounded-bl-[50%] group m-auto lg:m-0 h-72 mt-0 md:w-2/5 hidden md:flex'>
                        <img src="/presentation3-4.png" alt="logo" className='object-cover group-hover:scale-110 duration-700 h-full' />
                    </div>
                    <div className='flex flex-col rounded-bl-[50%] rounded-tl-[50%] group m-auto lg:m-0 h-72 mt-0 md:w-2/5'>
                        <img src="/presentation4-4.png" alt="logo" className='object-cover lg:blur-[2px] group-hover:blur-none duration-300 h-full' />
                    </div>
                </div>

            </div>
            <div id='bloc2' className={`panel h-full xl:h-screen lg:flex-screen flex flex-col-reverse xl:flex-row w-full mb-12  border-b-2 border-[#2a2448] md:border-b-0 md:overflow-hidden xl:items-center `}>
                <Links className='flex flex-col justify-center lg:p-5 lg:pr-0 lg:w-full xl:w-1/2 max-h-[600px] max-w-[1000px] xl:max-w-full xl:max-h-full  '>
                    <iframe className='rounded ' height="590" src="https://www.youtube.com/embed/NPImWyS5b2M?si=ysnOaPDdewMeD94x" title="FORMATION C I A R A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Links>
                <div className='flex flex-col md:m-auto justify-center xl:m-10  xl:w-1/2 rounded   bg-[#13062c] p-6 rounded shadow-xl border-b-2 border-[#2a2448] xl:border-b-0'>
                    <h1 className='text-2xl mb-5 text-bold'>La formation CIARA de CREATIVE HANDICAP</h1>
                    <p className={`text-lg  ${lineHeight} ${letterSpacing} ${fontSize}`}>La formation CIARA est une <strong>formation professionnelle GRATUITE </strong>
                        <strong>à destination des jeunes en situation de handicap et des décrocheurs
                            scolaires de l'âge de 18 à 30 ans sur les métiers de la création
                            numérique</strong> : jeux vidéo, animation 2D/3D, réalité augmentée, réalité
                        virtuelle et Métaverse.
                        <br></br><br></br>
                        Elle se déroule sur deux ans : une année préparatoire et deux modules
                        de spécialisation : un module en <strong>programmation visuelle, médias 3D et
                            VR</strong> et un module en <strong>design graphique 2D et 3D</strong>.
                        <br></br><br></br>
                        Les enseignements se concentrent autour de l'utilisation et de la
                        pratique des logiciels :
                        <br></br> <br></br>
                        <strong>Unreal Engine</strong>
                        <br></br> <br></br>
                        <strong>Unity</strong>
                        <br></br> <br></br>
                        <strong>Blender</strong>
                        <br></br><br></br>
                        <strong>Suite ADOBE (Photoshop, Illustrator, Indesign, After effect)</strong></p>
                </div>


            </div>
            <div className={` panel lg:h-screen flex flex-wrap items-center border-b-2 border-[#2a2448]  border-t-2 mb-10`}>
                <div className='flex flex-col justify-center md:m-auto  p-12 rounded lg:m-24 w-full  bg-[#13062c] p-6 rounded shadow-xl '>
                    <h1 className='text-2xl lg:mb-5'>Nos Valeurs</h1>
                    <p className={`text-lg  ${lineHeight} ${letterSpacing} ${fontSize}`}><strong>Le plein emploi des personnes en situation de handicap (PSH)</strong> est loin d’être atteint. <br></br> <br></br>
                        Les entreprises de plus de 20 salariés ayant l’obligation d’embaucher au moins 6% de PSH préfèrent
                        payer la taxe AGEFIPH plutôt que d’accueillir les travailleurs handicapés par manque de <strong>sensibilisation et
                            formation</strong>. <br></br><br></br>
                        Pour faciliter l'intégration professionnelle de nos apprenants et prouver leur compétences auprès des
                        entreprises, <strong>Creative Handicap a créé le Studio PLACE DES VICTOIRES</strong> recrutant des PSH sur leurs
                        "Mad Skills"*:
                        <br></br><br></br>
                        *Les Mad Skills sont empreintes d'originalité, elles peuvent souligner un particularisme et sont le plus souvent révélatrices de traits de caractères
                        atypiques et décalés.</p>
                </div>
            </div>

            <div className={` panel flex flex-col   w-full h-full  md:pt-12 mb-[-100px] md:mb-0 md:pb-12`}>
                <div className='flex justify-center m-auto w-full md:h-2/5  mb-0 '>

                    <div className='flex flex-wrap gap-5 '>
                        <Gallery images={images} version={'prestation'} />
                    </div>
                </div>
                <div className='flex flex-col justify-center m-auto md:w-4/5 xl:w-fit xl:ml-10 xl:mr-10 h-fit p-6 rounded mb-0 md:mt-10  bg-[#13062c] rounded shadow-xl'>
                    <h1 className='text-2xl mb-5'>Nos équipes:</h1>
                    <p className={`text-lg  ${lineHeight} ${letterSpacing} ${fontSize}`}>
                        <strong>Place des Victoires</strong>, c’est avant tout une équipe de talents <strong>atypiques</strong> accompagnés de professionnels de la <strong>création numérique et de l’inclusion
                            socio-professionnelle de personnes en situation de handicap</strong>.
                        <br></br><br></br>
                        <strong>Nos profils atypiques et issus de la neurodiversité</strong> possèdent des capacités particulières telles que <strong>la créativité, la concentration, la logique,
                            l’imagination, la minutie, la pensée visuelle, et bien d’autres</strong>. Elles possèdent <strong>des compétences qui leur permettent de penser de manière
                                différente, de trouver des raccourcis, de mémoriser de nombreux détails qui échapperaient aux normo pensants, de proposer des solutions
                                inattendues, ou encore de se focaliser très longtemps sur une seule et même tâche</strong>.
                    </p>
                </div>

            </div>


        </QuiSommeNousContainer>
    )
}

export default QuiSommeNous