
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import colors from '../utils/color'
import Header from './header'
import ContactForm from './contactform'
import Footer from './footer'
import { AccessibilityProvider, useAccessibility } from '../context/AccessibilityContext';
import ModuleAccessibility from './moduleAccessibility'



const GlobalStyle = createGlobalStyle`
  body {
    cursor: default;
    padding: 0;
    font-family: 'Luciole', sans-serif;
    margin: 0;
    background-color: rgb(19 6 44 / 95%);
    color: ${colors.text};
    font-size: var(--font-size-base); /* Utilisation de la variable CSS pour la taille de police de base */
    line-height: var(--line-height-base); /* Assurez-vous d'utiliser une variable pour la hauteur des lignes si nécessaire */
  }

 
body.black-large-cursor {
    cursor: url('arrow-pointer-solid.svg'), auto;
}

body.white-large-cursor {

   cursor: url('arrow-pointer-solid (1).svg'), auto;
}
  
  .links {
    text-decoration: none;
    color: white;
  }

  /* Définition des tailles de texte et des hauteurs de lignes*/
  :root {
    --font-size-base: 1rem; /* Taille de texte de base */
    --font-size-lg: calc(var(--font-size-base) * 1.125); /* text-lg */
    --font-size-xl: calc(var(--font-size-base) * 1.25); /* text-xl */
    --font-size-2xl: calc(var(--font-size-base) * 1.5); /* text-2xl */
      
    --line-height-base: 1.5; /* Hauteur de ligne de base */
    --line-height-md: 1.75; /* Hauteur de ligne intermédiaire */
    --line-height-lg: 2; /* Grande hauteur de ligne */

     --letter-spacing-base: 0;
  --letter-spacing-md: 0.05em;
  --letter-spacing-lg: 0.1em;
  }

  @media (min-width: 640px) { /* sm */
  :root {
    --font-size-base: 1.125rem; /* Ajuster la taille de base pour les petits écrans */
    --line-height-base: 1.55;
  }
}

@media (min-width: 768px) { /* md */
  :root {
    --font-size-base: 1.25rem; /* Ajuster la taille de base pour les écrans moyens */
    --line-height-base: 1.6;
  }
}

@media (min-width: 1024px) { /* lg */
  :root {
    --font-size-base: 1.375rem; /* Ajuster la taille de base pour les grands écrans */
    --line-height-base: 1.65;
  }
}

@media (min-width: 1280px) { /* xl */
  :root {
    --font-size-base: 1.5rem; /* Ajuster la taille de base pour les très grands écrans */
    --line-height-base: 1.7;
  }
}


  /* Définition des classes de taille de texte */
  .text-base {
    font-size: var(--font-size-base);
  }

  .text-lg {
    font-size: var(--font-size-lg);
  }

  .text-xl {
    font-size: var(--font-size-xl);
  }

  .text-2xl {
    font-size: var(--font-size-2xl);
  }

  /* Définition des classes de hauteur de ligne avec une spécificité accrue */
html body .line-height-base {
  line-height: var(--line-height-base) !important;
}

html body .line-height-md {
  line-height: var(--line-height-md) !important;
}

html body .line-height-lg {
  line-height: var(--line-height-lg) !important;
}


/* Définition de l'espacement des lettres */
.letter-spacing-base {
  letter-spacing: var(--letter-spacing-base);
}

.letter-spacing-md {
  letter-spacing: var(--letter-spacing-md);
}

.letter-spacing-lg {
  letter-spacing: var(--letter-spacing-lg);
}

/* Classe pour la bordure colorée des liens */
.link-highlight {
    border: 2px solid white; /* Bordure colorée, ajustez selon vos besoins */
    padding: 4px; /* Optionnel : ajoutez un peu de padding pour que la bordure ne se chevauche pas avec le texte */ 
}


  .white-icon {
    filter: brightness(0) invert(1);
  }

  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .typing-effect {
    border-right: 2px solid;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 6s steps(40, end), blink-caret .75s step-end;
  }

  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: black; }
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .rotate-effect {
    animation: rotate 2s linear;
  }

  @keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }

  .blinking-effect {
    animation: blinking 2s;
  }

  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }

  .marquee-effect {
    animation: marquee 10s linear;
  }

  @keyframes zoomIn {
    from { transform: scale(0.2); }
    to { transform: scale(1); }
  }

  .zoom-in-effect {
    animation: zoomIn 1.5s ease-out;
  }
 
`;




/* Component Layout (component to display header, footer and children) */
const Layout = ({ children }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fontSize, lineHeight } = useAccessibility();


  useEffect(() => {
    let baseFontSize;

    switch (fontSize) {
      case 'text-sm':
        baseFontSize = '0.875rem'; // 14px
        break;
      case 'text-base':
        baseFontSize = '1rem'; // 16px
        break;
      case 'text-lg':
        baseFontSize = '1.125rem'; // 18px
        break;
      case 'text-xl':
        baseFontSize = '1.25rem'; // 20px
        break;
      case 'text-2xl':
        baseFontSize = '1.5rem'; // 24px
        break;
      default:
        baseFontSize = '1rem'; // Valeur par défaut
    }

    document.documentElement.style.setProperty('--font-size-base', baseFontSize);
  }, [fontSize]);


  return (
    <React.Fragment >
      <GlobalStyle />
      <ContactForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <main >
        {children}
        <Outlet />{' '}
      </main>
      <ModuleAccessibility />
      <Footer />
    </React.Fragment>
  )
}

export default Layout